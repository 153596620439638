<template>
  <div>
    <a-menu
      :default-selected-keys="[activeKey]"
      mode="horizontal"
    >

      <a-menu-item key="agency_info">
        <router-link :to="{ name: 'agency_info' }">客户信息
        </router-link>
      </a-menu-item>

      <a-menu-item key="agency_products" class="ant-menu-spacing">
        <router-link :to="{ name: 'agency_products' }">客户套餐
        </router-link>
      </a-menu-item>

      <a-menu-item key="agency_sim_cards" class="ant-menu-spacing">
        <router-link :to="{ name: 'agency_sim_cards' }">卡号列表
        </router-link>
      </a-menu-item>

      <a-menu-item key="agency_traffic_pools" class="ant-menu-spacing">
        <router-link :to="{ name: 'agency_traffic_pools' }">流量池
        </router-link>
      </a-menu-item>

      <a-sub-menu class="ant-menu-spacing">
        <span slot="title">服务周期管理</span>
        <a-menu-item key="agency_service_cycle_unexpired">
          <router-link :to="{ name: 'agency_service_cycle_unexpired' }">
            服务周期即将到期
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_service_cycle_expired">
          <router-link :to="{ name: 'agency_service_cycle_expired' }">
            服务周期已到期
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_current_month_expect_cancel">
          <router-link :to="{ name: 'agency_current_month_expect_cancel' }">
            本月预计销卡列表
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_renewed">
          <router-link :to="{ name: 'agency_service_cycle_renewed' }">
            续期记录
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_canceled">
          <router-link :to="{ name: 'agency_service_cycle_canceled' }">
            销卡记录
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu class="ant-menu-spacing">
        <span slot="title">财务信息</span>
        <a-menu-item key="agency_monthly_bills">
          <router-link :to="{ name: 'agency_monthly_bills' }">
            月账单
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_transfers">
          <router-link :to="{ name: 'agency_transfers' }">
            收付款记录
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_balance_statistics">
          <router-link :to="{ name: 'agency_balance_statistics' }">
            余额
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu class="ant-menu-spacing" v-if="this.agentType == 1">
        <span slot="title">分佣信息</span>
        <a-menu-item key="agency_monthly_bills_commission">
          <router-link :to="{ name: 'agency_monthly_bills_commission' }">
            月订单
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_withdrawals">
          <router-link :to="{ name: 'agency_withdrawals' }">
            提现记录
          </router-link>
        </a-menu-item>
        <a-menu-item key="agency_commission_statistics" v-if="this.agentType == 1">
          <router-link :to="{ name: 'agency_commission_statistics' }">
            佣金
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="agency_api_info">
        <router-link :to="{ name: 'agency_api_info' }">开发信息
        </router-link>
      </a-menu-item>
    </a-menu>
    <router-view class="router-view" />
  </div>
</template>

<script>
import { findAgentName } from '@/api/agent'

export default {
  name: 'AgencyShowTab',
  data() {
    return {
      activeKey: '',
      agencyMonthlyBillChildrenTabs: [
        'agency_monthly_bill_info',
        'agency_monthly_bill_allocation_order_info',
        'agency_monthly_bill_card_use_info',
        'agency_monthly_bill_renew_order_info'
      ],
      agencyTrafficPoolChildrenTabs: [
        'agency_traffic_pools',
        'agency_traffic_pool_info',
        'agency_traffic_pool_sim_cards',
        'agency_traffic_pool_expand_records',
        'agency_traffic_pool_usage_orders'
      ]
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    }
  },
  watch: {
    agentId() {
      this.setBreadcrumb()
    }
  },
  created() {
    this.setActiveKey()
    this.setBreadcrumb()
  },
  methods: {
    setActiveKey() {
      const routeName = this.$route.name
      if (this.agencyTrafficPoolChildrenTabs.includes(routeName)) {
        this.activeKey = 'agency_traffic_pools'
      } else if (this.agencyMonthlyBillChildrenTabs.includes(routeName)) {
        this.activeKey = 'agency_monthly_bills'
      } else {
        this.activeKey = routeName
      }
    },

    setBreadcrumb() {
      findAgentName(this.agentId).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agency_name': res.data.name })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-menu-spacing {
  margin-left: 20px;
}

.router-view {
  margin-top: 10px;
}
</style>
